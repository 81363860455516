import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import './styles/Home.css';
import CreateProjectModal from '../components/common/projects/CreateProjectModal';
import ActionCard from '../components/common/ActionCard';
import { AV_MODAL } from '../components/shared/AV_MODAL';
import CreateEditTransactionForm from '../components/common/transactions/CreateTransactionForm';

export const Home = () => {
	const { userData } = useAuth();
	const userId = userData ? userData._id : null;
	const [activeModal, setActiveModal] = useState(null);
	const [isCreateTransactionModalOpen, setIsCreateTransactionModalOpen] =
		useState(false);

	const openModal = (modalType) => setActiveModal(modalType);
	const closeModal = () => setActiveModal(null);

	const modalComponents = {
		createProject: CreateProjectModal,
	};

	const handleCardClick = (modalType) => {
		openModal(modalType);
	};

	const renderModal = () => {
		if (!activeModal) return null;

		const ModalComponent = modalComponents[activeModal];
		return (
			<ModalComponent
				open={!!activeModal}
				onClose={closeModal}
				isNewProject={true}
				user={userId}
			/>
		);
	};

	return (
		<>
			{/* <HeaderTop /> */}
			<div className="home-container">
				<Grid centered columns={3}>
					<Grid.Row centered>
						<Grid.Column textAlign="center">
							<ActionCard
								iconName="edit"
								iconColor="var(--primary-color)"
								description="Create Project"
								onClick={() => handleCardClick('createProject')}
							/>
						</Grid.Column>
						<Grid.Column textAlign="center">
							<ActionCard
								iconName="exchange"
								iconColor="var(--accent-color)"
								description="Create Transaction"
								onClick={() => setIsCreateTransactionModalOpen(true)}
							/>
						</Grid.Column>
						<Grid.Column textAlign="center">
							<Link to="/projects">
								<ActionCard
									iconName="dashboard"
									iconColor="var(--grey-color)"
									description="Go To Dashboard"
								/>
							</Link>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{renderModal()}
				<AV_MODAL
					scrolling={false}
					title={'Create Transaction'}
					visible={isCreateTransactionModalOpen}
					handleCancel={() => {
						setIsCreateTransactionModalOpen(false);
					}}
					size={'large'}
					children={
						<CreateEditTransactionForm
							mode={'create'}
							project={undefined}
							handleCancel={() => {
								setIsCreateTransactionModalOpen(false);
							}}
						/>
					}
				/>
			</div>
		</>
	);
};
